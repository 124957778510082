import React from "react"
import MiscContentPost from "./layouts/MiscContentPost"
import ImageContentPost from "./layouts/ImageContentPost";

export const getPostLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContentPost":
      return <MiscContentPost {...layout} />
    case "ImageContentPost":
      return <ImageContentPost {...layout} />
  }
}
