import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPostLayout } from "../../get-post-layout-utils"
import ArticleHero from "../../layouts/ArticleHero"
import ShareContent from "../../components/share-content/share-content.component"
import DownloadWhitePaper from "../../components/download-white-paper/download-white-paper.component"
import OtherPostGridPagination from "../../components/other-post-grid-pagination/other-post-grid-pagination.component"
import { Container } from "@mui/material"

export const query = graphql`
  query PostQuery($id: String!, $categoryId: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      uri
      slug
      date(formatString: "MMMM DD, YYYY")
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      postBuilder {
        layouts {
          ... on WpPost_Postbuilder_Layouts_MiscContentPost {
            ...MiscContentPost
          }
          ... on WpPost_Postbuilder_Layouts_ImageContentPost {
            ...ImageContentPost
          }
        }
        whitePaperArchive {
          title
          url
        }
        pageConfiguration {
          hideFooter
        }
      }
    }
    allWpPost(
      filter: {
        id: { nin: [$id] }
        categories: { nodes: { elemMatch: { id: { eq: $categoryId } } } }
      }
    ) {
      nodes {
        title
        categories {
          nodes {
            name
            slug
          }
        }
        uri
        date
        slug
        featuredImage {
          node {
            altText
            sourceUrl
            title
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`
const PostTemplate = ({ data }) => {
  const {
    seo,
    slug,
    postBuilder,
    title,
    featuredImage,
    categories,
    author,
    date,
    uri,
  } = data.wpPost

  const layouts = postBuilder.layouts || []

  return (
    <Layout {...postBuilder.pageConfiguration} seo={seo} title={title}>
      <ArticleHero
        image={featuredImage?.node}
        title={title}
        date={date}
        // author={author?.node?.name}
        category={categories?.nodes[0]?.name}
      />
      {layouts.map(layout => getPostLayout(layout))}
      <DownloadWhitePaper url={postBuilder?.whitePaperArchive?.url} />
      <ShareContent
        description={"Like this article? Share it"}
        postUrl={`/articles/${slug}`}
        // haveVideo
      />
      <Container maxWidth="xl">
        <OtherPostGridPagination
          titleSection={"Related Articles"}
          articles={data.allWpPost?.nodes}
          postPerPage={2}
          idFeatured="0"
          pageTitle="Articles"
        />
      </Container>
    </Layout>
  )
}

export default PostTemplate
