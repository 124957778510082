import React from "react"

import * as S from "./download-white-paper.styles"
import { FileDownloadIcon } from "./download-white-paper.styles"

const DownloadWhitePaper = ({ url }) => {
  if (!url) return null
  return (
    <S.LinkContainer>

      <S.DownloadLink href={url}>
          <S.DownloadWrapper>
              <span>Download Whitepaper</span>
              <S.FileDownloadIcon />
          </S.DownloadWrapper>
      </S.DownloadLink>
    </S.LinkContainer>
  )
}

export default DownloadWhitePaper
