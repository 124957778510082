import styled from "styled-components"
import CustomLink from "../custom-link/custom-link.component"
import { FileDownload } from "@mui/icons-material"

export const LinkContainer = styled.div`
  padding: 1em 0 5em 0;
  width: 100%;
  max-width: 628px;
  margin: 0 auto;
  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 1rem;
  }
`
export const DownloadLink = styled(CustomLink)`
  
`

export const FileDownloadIcon = styled(FileDownload)`
  color: ${({ theme }) => theme.palette.secondary.dark};
  margin-left: 0.5em;
  margin-bottom: -0.1em;
  width: 24px;
  height: 24px;
`

export const DownloadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.down("md")} {
    padding: 0 0 2em;
  }
  
  span {
    color: ${({ theme }) => theme.palette.secondary.dark};
    font-family: ${({ theme }) => theme.fonts.primary};
    font-size: 1.12em;
    font-weight: 500;
    height: 24px;
    text-align: left;

    ${({ theme }) => theme.breakpoints.down("md")} {
      text-align: center;
      font-size: 1em;
    }
  }
`